<template>
  <div class="ReviewScore row">
    <div class="ml-1">
      <va-icon
        v-for="star in count"
        color="warning"
        :key="star"
        name="fa fa-star"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReviewScore',
  props: ['count'],
}
</script>
