<template>
  <div class="ReviewPage">
    <!-- Review Form -->
    <form @submit.prevent="saveChanges">
      <va-card stripe="secondary">

        <!-- Controls -->
        <div class="row">
          <div class="flex xs6 md10">
            <h1 class="display--1 text--capitalize">{{ mode }} Review Form</h1>
          </div>
          <div class="flex xs6 md2">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>
        </div>

        <!-- Row 1 -->
        <div class="row">
          <!-- Review Username -->
          <div class="flex md6 xs12">
            <va-input
              label="Username"
              v-model="review.username"
              placeholder="Type username here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-header"
              />
            </va-input>
          </div>

          <!-- Rating-->
          <div class="flex md3 xs12">
            <va-select
              label="Rating"
              v-model="reviewRating"
              :options="quantityOptions"
            />
          </div>

          <!-- Review image -->
          <div class="flex md3 xs12">
            <va-input
              label="Review ID"
              v-bind:disabled="mode === 'edit'"
              v-model="review.revID"
              placeholder="Type Review ID here (EG: REV20)"
            >
              <va-icon
                slot="prepend"
                v-bind:color="mode === 'edit' ? 'gray' : 'success'"
                name="fa fa-file-text"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">
          <!-- Comment -->
          <div class="flex md8 xs12">
            <h6 class="display--6">Comment</h6>
            <textarea
              id="Comment"
              rows="7"
              cols="75"
              placeholder="Type Comment Here"
              v-model="review.comment">
          </textarea>
          </div>

          <!-- Date -->
          <div class="flex md4 xs12">
            <va-date-picker
              label="Data Range"
              :config="{inline: true}"
              v-model="calendarDateTime"
            />
          </div>
        </div>
      </va-card>

      <!-- Row 3 -->
      <va-card class="my-3" stripe="warning">
        <!-- Review Image-->
        <h1>Reviewer Image</h1>

        <div class="row">
          <!-- Image Preview -->
          <div class="flex xs12 md2">
            <img
              class="img-fluid"
              v-bind="userIcon"
              :src="review.img.length > 1 ? review.img : defaultUserIcon"
              alt="Reviewer Profile Photo"
            />
          </div>

          <!-- Image Url -->
          <va-input
            class="flex xs12 md10"
            label="Add New Image"
            v-model="review.img"
            placeholder="Type Image url Here"
          >
            <va-icon
              slot="prepend"
              color="success"
              name="fa fa-camera"
            />
          </va-input>
        </div>
      </va-card>

      <!-- Controls Bottom -->
      <div class="row">

        <!-- Go Back -->
        <div class="flex xs12 md9">
          <va-button
            outline
            medium
            color="info"
            type="button"
            icon="fa fa-arrow-left"
            v-on:click="goBack">
            Back
          </va-button>
        </div>

        <!-- Save form -->
        <div class="flex xs12 md3">
          <va-button
            medium
            color="info"
            type="submit"
            icon="fa fa-save"
          >
            Save Review
          </va-button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import { timestamp, reviewsCollection } from '@/firebaseConfig.js'

export default {
  name: 'ReviewPage',
  props: ['mode', 'review'],
  data () {
    return {
      reviewRating: '',
      calendarDateTime: '',
      userIcon: { width: 100, height: 100, class: 'm1' },
      quantityOptions: [...Array(6).keys()].join(' ').split(' '),
      defaultUserIcon: 'https://www.lantabus.com/wp-content/uploads/2017/02/Alert_Icon_Orange.png',
    }
  },
  methods: {
    goBack: function () {
      this.$emit('goBack')
    },

    createFirebaseTimestamp: function (date) {
      return timestamp.fromDate(date)
    },

    epochToGMT: function (epoch) {
      return new Date(epoch.seconds * 1000)
    },

    epochToCalenderDate: function (epoch) {
      const reviewDate = this.epochToGMT(epoch)
      return reviewDate.getFullYear() + '-' + (reviewDate.getMonth() + 1) + '-' + reviewDate.getDate()
    },

    calenderDateToEpoch: function (datetime) {
      const date = datetime.split('-')
      return this.createFirebaseTimestamp(new Date(date[0], date[1] - 1, date[2]))
    },

    saveChanges: function () {
      const _this = this
      this.review.rating = parseInt(this.reviewRating)
      this.review.date = this.calenderDateToEpoch(this.calendarDateTime)

      reviewsCollection
        .doc(this.review.revID)
        .set(this.review)
        .then(() => {
          _this.$emit('saveReview', _this.review)
          _this.goBack()
        })
    },
  },

  mounted () {
    this.calendarDateTime = this.epochToCalenderDate(this.review.date)
    this.reviewRating = this.review.rating.toString()
  },
}
</script>
