<template>
  <div class="Reviews">

    <!-- Header -->
    <ObjectPageHeader
      v-bind:objectName="objectName"
      v-on:refreshData="refreshData($event)">
    </ObjectPageHeader>

    <!-- Info Tiles -->
    <div class="row">
      <div
        class="flex xs12 md3"
        v-for="(info, idx) in infoTiles"
        :key="idx"
      >
        <va-card class="mb-4" :color="info.color">
          <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
          <p>{{info.text}}</p>
        </va-card>
      </div>

      <div class="flex xs12 md5">
        <va-card class="mb-4" color="orange">
          <p v-on:click="createReview" class="display-2 mb-0" style="color: white;">Create New Review</p>
          <p v-on:click="createReview">Need more Reviews? <u>Click here</u> to start</p>
        </va-card>
      </div>
    </div>

    <!-- Data table -->
    <va-card v-if="!showReview" title="All Reviews">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Review name"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <!-- Reviewer Icon -->
        <template slot="img" slot-scope="props">
          <img
            v-bind="userIcon"
            :src="props.rowData.img"
            class="img-fluid"
            alt="User's profile image"
          />
        </template>

        <!-- Review Date -->
        <template slot="date" slot-scope="props">
          <p>{{ epochToGMT(props.rowData.date) }}</p>
        </template>

        <!-- Review Stars -->
        <template slot="rating" slot-scope="props">
          <div class="row align--center">
            <ReviewScore
              class="ml-1"
              v-bind:count="props.rowData.rating">
            </ReviewScore>
          </div>
        </template>

        <!-- Review Comment -->
        <template slot="comment" slot-scope="props">
          <p>{{ shortenComment(props.rowData.comment) }}</p>
        </template>

        <!-- Actions -->
        <template slot="actions" slot-scope="props">

          <va-popover :message="`Edit Review ${props.rowData.revID}`" placement="left">
            <va-button flat small color="info" icon="fa fa-pencil" v-on:click="editReview(props.rowData)"/>
          </va-popover>

          <va-popover :message="`${$t('tables.delete')} Review ${props.rowData.revID}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteReview(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

    <ReviewPage v-else
      v-bind:mode="mode"
      v-bind:review="selectedReview"
      v-on:saveReview="saveReview($event)"
      v-on:goBack="goBack()">
    </ReviewPage>

  </div>

</template>

<script>
import { debounce } from 'lodash'
import { timestamp, reviewsCollection } from '@/firebaseConfig.js'
import ReviewPage from '@/components_tc/reviews/ReviewPage.vue'
import ReviewScore from '@/components_tc/reviews/ReviewScore.vue'
import ObjectPageHeader from '@/components_tc/utilities/ObjectPageHeader.vue'

export default {
  name: 'reviews',
  components: {
    ObjectPageHeader,
    ReviewPage,
    ReviewScore,
  },
  data () {
    return {
      term: null,
      mode: '',
      perPage: '10',
      reviews: [],
      objectName: '',
      loading: false,
      averageRating: 0,
      showReview: false,
      selectedReview: {},
      perPageOptions: ['10', '25', '50', '100'],
      userIcon: { width: 60, height: 60, class: 'm1' },
      infoTiles: [{
        color: 'secondary',
        value: '0',
        text: 'Published Reviews',
        icon: '',
      }, {
        color: 'info',
        value: '0',
        text: 'Average Rating',
        icon: '',
      }],
    }
  },

  computed: {
    Reviews: function () {
      return this.$store.state.reviews
    },
    fields () {
      return [
        {
          name: '__slot:img',
          title: this.$t('Icon'),
          width: '5%',
        }, {
          name: 'username',
          title: this.$t('Name'),
          sortField: 'username',
          width: '15%',
        }, {
          name: '__slot:date',
          title: this.$t('Date'),
          sortField: 'date',
          width: '15%',
        }, {
          name: '__slot:rating',
          title: this.$t('Rating'),
          sortField: 'rating',
          width: '13%',
        }, {
          name: '__slot:comment',
          title: this.$t('Comment'),
          sortField: 'comment',
          width: '35%',
        }, {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.reviews
      }

      return this.reviews.filter(item => {
        return item.username.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    refreshData: function (event) {
      this.getReviewData(event)
    },

    goBack: function () {
      this.showReview = false
    },

    shortenComment: function (comment) {
      if (comment.length > 265) {
        return comment.substr(0, 262) + '...'
      } else { return comment }
    },

    epochToGMT: function (epoch) {
      const date = new Date(epoch.seconds * 1000)
      return date.toDateString()
    },

    createFirebaseTimestamp: function () {
      const firebaseTimestamp = timestamp.fromDate(new Date())
      return firebaseTimestamp
    },

    createReview: function () {
      this.mode = 'create'
      this.selectedReview = {
        comment: '',
        date: this.createFirebaseTimestamp(),
        img: '',
        rating: 0,
        revID: 'REV' + (this.reviews.length + 1),
        username: '',
      }
      this.showReview = true
    },

    editReview: function (reviewData) {
      this.mode = 'edit'
      this.selectedReview = reviewData
      this.showReview = true
    },

    saveReview (review) {
      this.showToast(
        'Review Saved',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    deleteReview: function (reviewData, reviewIndex) {
      const _this = this
      this.loading = true

      reviewsCollection
        .doc(reviewData.revID)
        .delete()
        .then(function () {
          _this.deleteFromTable(reviewData, reviewIndex)
          _this.showToast(
            'Review Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (reviewData, reviewIndex) {
      let realIndex
      const reviewIndexRev = (this.reviews.length - 1) - reviewIndex

      if (reviewData.revID === this.reviews[reviewIndex].revID) {
        realIndex = reviewIndex
      } else if (reviewData.revID === this.reviews[reviewIndexRev].revID) {
        realIndex = reviewIndexRev
      }

      this.reviews.splice(realIndex, 1)
      this.$store.commit('setReviews', this.reviews)
      this.loading = false
    },

    getReviewData: function (event) {
      var _this = this
      this.loading = true

      if (this.Reviews.reviewList === undefined || this.Reviews.reviewList.length === 0 || event != null) {
        reviewsCollection
          .get()
          .then(function (dataSnapshot) {
            let sumRatings = 0
            const jsonReviews = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              jsonReviews.push(itemData)
              sumRatings += itemData.rating
            })

            _this.reviews = jsonReviews.sort((a, b) => (a.date < b.date) ? 1 : -1)
            _this.averageRating = (sumRatings / _this.reviews.length).toFixed(1)
            const reviewsObject = {
              rating: _this.averageRating,
              reviewList: _this.reviews,
            }
            _this.$store.commit('setReviews', reviewsObject)

            _this.updateInfoTiles(_this.reviews, _this.averageRating)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.reviews = this.Reviews.reviewList
        this.averageRating = this.Reviews.rating
        this.updateInfoTiles(this.reviews, this.averageRating)
        this.loading = false
      }
    },

    updateInfoTiles: function (totalReviews, averageRating) {
      this.infoTiles[0].value = totalReviews.length
      this.infoTiles[1].value = averageRating
    },

  },

  mounted () {
    this.objectName = this.$options.name
    this.getReviewData(null)
  },

}
</script>
